import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
      <div className='home'>
        <h1>Simple <br/>Schedule</h1>
        <Link to="/join">
          <button>Join</button>
        </Link>
      </div>
    );
};

export default Home;