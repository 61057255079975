import React, { useEffect, useLayoutEffect, useState, useRef, useContext } from 'react';
import moment from 'moment'
import { FeedContext } from './FeedContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCalendarWeek, faCalendarDay, faClock, faBoltLightning, faEye, faEyeSlash, faCalendarXmark, faQuestion, faFileCircleXmark, faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';
import DisclosureComponent from './DisclosureComponent';
import EventIconLabel from '../components/EventIconLabel';
import '../styles/EventComponent.css'

const EventComponent = ({ eventId, closeEvent, openMessage }) => {
    const { events, updateEvent } = useContext(FeedContext);
    const [event, setEvent] = useState(null);
    const drawerRef = useRef(null);
    const baseRef = useRef(null);
    const descriptionRef = useRef(null);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const currentYRef = useRef(0);
    const [like, setLike] = useState(null);
    const [dislike, setDislike] = useState(null);
    const [firstView, setFirstView] = useState(null);
    const [isClampAdjusted, setIsClampAdjusted] = useState(false);
    const [disclosureOpen, setDisclosureOpen] = useState(false);
    const [hideLabel, setHideLabel] = useState(null);
    const [hideIcon, setHideIcon] = useState(null);

    useEffect(() => {
        if (events && eventId) {
            const fetchedEvent = events.get(eventId);
            if (fetchedEvent) {
                setEvent(fetchedEvent);
                setLike(fetchedEvent.like);
                setDislike(fetchedEvent.dislike);
            }
        }
    }, [events, eventId]);


    // Function to open hyperlink in a new window
    const handleOpenLink = (hyperlink) => {
        if (hyperlink) {
            window.open(hyperlink, "_blank", "noopener,noreferrer");
        }
    };

    const handleDislikeClick = (dislikeReason) => {
        const toggle = !dislike; // Toggle the dislike value

        if (!toggle) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        fetch('/api/events/' + eventId + '/dislike', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle, dislikeReason })
        })
            .then(response => {
                if (response.ok) {
                    setDislike(toggle);
                    updateEvent({ ...event, dislike: toggle });
                } else {
                    // Handle errors here
                    console.error('Failed to update dislike');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        setDisclosureOpen(false);
    }

    const toggleDisclosure = (openState) => setDisclosureOpen(openState);

    const handleLikeClick = () => {
        const toggle = !like;

        fetch('/api/events/' + eventId + '/like', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ toggle: toggle }),
        })
            .then(response => {
                if (response.ok) {
                    setLike(toggle);
                    updateEvent({ ...event, like: toggle });
                } else {
                    // Handle errors here
                    console.error('Failed to update likes');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const labelHide = 'Hide Event';
    const labelUnhide = "Unhide Event";
    const labelDateIncorrect = 'Wrong date or time'
    const labelDetailsIncorrect = 'Details are incorrect'
    const labelIrrelevant = 'Irrelevant info'

    const disclosureItems = [
        { label: hideLabel, icon: hideIcon, onClick: () => handleDislikeClick(null) },
        { label: labelDateIncorrect, icon: faCalendarXmark, onClick: () => handleDislikeClick(labelDateIncorrect) },
        { label: labelDetailsIncorrect, icon: faFileCircleXmark, onClick: () => handleDislikeClick(labelDetailsIncorrect) },
        { label: labelIrrelevant, icon: faQuestion, onClick: () => handleDislikeClick(labelIrrelevant) },
    ];

    useEffect(() => {
        const drawer = drawerRef.current;
        // Ensure the drawer ref is not null
        if (!drawer) {
            return;
        }

        if (!dislike) {
            setHideLabel(labelHide);
            setHideIcon(faEyeSlash);
        } else {
            setHideLabel(labelUnhide);
            setHideIcon(faEye);
        }

        const handleTouchStart = (e) => {
            drawer.style.animation = 'none' // Clear animation when starting the drag
            drawer.style.transition = ''; // Remove the transition to avoid interference with other animations or drags
            startYRef.current = currentYRef.current = e.touches[0].clientY; // Use ref to store startY
            isDraggingRef.current = true;
        };

        const handleTouchMove = (e) => {
            if (isDraggingRef.current) {
                e.preventDefault();

                const diffY = e.touches[0].clientY - currentYRef.current;

                const drawerBottom = parseFloat(getComputedStyle(drawerRef.current).bottom);

                const setY = drawerBottom - diffY;

                if (setY <= 0) {
                    // Move the drawer exactly as much as the diffY value
                    drawer.style.bottom = `${setY}px`;

                    // Update currentYRef.current so that the next move is based on the new position
                    currentYRef.current = e.touches[0].clientY;
                } else {
                    // Move drawer to top position at the end of movement
                    drawer.style.bottom = `0px`;
                }
            }
        };


        const handleTouchEnd = () => {
            isDraggingRef.current = false;

            const drawer = drawerRef.current;
            const drawerBottom = Math.abs(parseFloat(getComputedStyle(drawer).bottom));

            // Add a smooth transition for the bottom property
            drawer.style.transition = 'bottom 0.15s ease-in-out';

            // Check if the drawer has been dragged beyond the halfway point of its height
            if (drawerBottom > drawer.getBoundingClientRect().height / 2) {
                drawer.style.bottom = '-100%'; // Hide drawer
                setTimeout(() => {
                    closeEvent(); // Close after transition is complete
                }, 150);

            } else {
                drawer.style.bottom = '0'; // Reset position
            }
        };

        // Attach event listeners
        drawer.addEventListener('touchstart', handleTouchStart);
        drawer.addEventListener('touchmove', handleTouchMove);
        drawer.addEventListener('touchend', handleTouchEnd);

        if (drawerRef.current) {
            drawerRef.current.classList.add('event-loaded');
        }

        return () => {

            if (drawer) {
                drawer.removeEventListener('touchstart', handleTouchStart);
                drawer.removeEventListener('touchmove', handleTouchMove);
                drawer.removeEventListener('touchend', handleTouchEnd);
            }

        };
    }, [eventId]);

    const postFirstView = () => {
        if (event && !event.firstView) { // Only set once
            fetch('/api/events/' + eventId + '/firstview', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ firstView: true }),
            })
                .then(response => {
                    if (response.ok) {
                        setFirstView(true);
                        updateEvent({ ...event, firstView: true });
                    } else {
                        // Handle errors here
                        console.error('Failed to update firstView');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    useLayoutEffect(() => {

        postFirstView(); // Logs first view of the event

        if (descriptionRef.current && baseRef.current && !isClampAdjusted) {
            // Get the bounding rectangles of both the parent (eventRef) and the child (descriptionRef)
            const parentRect = baseRef.current.getBoundingClientRect();
            const descriptionRect = descriptionRef.current.getBoundingClientRect();

            // Check if the description is clipped by comparing their bottom edges
            const isClipped = descriptionRect.bottom > parentRect.bottom;

            if (isClipped) {
                // Calculate how much of the description is clipped
                const clipAmount = Math.ceil(descriptionRect.bottom - parentRect.bottom);
                const descriptionHeight = Math.ceil(descriptionRef.current.getBoundingClientRect().height);
                const computedStyle = window.getComputedStyle(descriptionRef.current);
                const padding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
                const margin = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom);
                let lineHeight = parseFloat(computedStyle.lineHeight); //lineHeight set in CSS

                // Default to font size multiplier if line height is invalid
                //const fontSize = parseFloat(computedStyle.fontSize);
                //if (isNaN(lineHeight) || lineHeight === 0) {
                //    lineHeight = fontSize * 1.3;
                //}

                const numberOfLines = Math.round((descriptionHeight - margin - padding) / lineHeight);
                const linesClipped = Math.ceil(clipAmount / lineHeight);
                const newLineClamp = numberOfLines - linesClipped;

                // Apply the adjusted line clamp
                descriptionRef.current.style.webkitLineClamp = newLineClamp;
                setIsClampAdjusted(true);
            }
        }
    }, [event]);

    const renderMentioners = (mention) => {
        const firstTwo = getFirstTwoInitials(mention.sourceSender);
        const colors = getColors(firstTwo);

        return (
            <div
                className="source"
                key={mention._id}
                onClick={(e) => {
                    e.preventDefault();
                    openMessage(mention.sourceMessageId);
                }}
                style={{
                    background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
                    border: `0.2rem solid ${colors.secondary}`
                }}
            >
                <div className="message-sender">{firstTwo}</div>
            </div>
        );
    };

    const renderMentionNote = (event) => {

        const mentionDates = [];
        // mentionDates.push(moment(event.sourceDate).format('MMM D'));

        let message = (event.mentions.length > 1 ? event.mentions.length + ' mentions' : '1 mention') + " on ";

        for (const mention of event.mentions) {
            mentionDates.push(moment(mention.sourceDate).format('MMM D'))
        }

        const uniqueMentionDates = [...new Set(mentionDates)];

        if (uniqueMentionDates.length == 1) {
            message += uniqueMentionDates[0];
        } else if (uniqueMentionDates.length == 2) {
            message += uniqueMentionDates.join(' and ')
        } else if (uniqueMentionDates.length > 2) {
            message += uniqueMentionDates.slice(0, -1).join(', ') + ', and ' + uniqueMentionDates[uniqueMentionDates.length - 1];
        }

        return message;
    }

    const renderedContent = (
        <div className="event-drawer" ref={drawerRef} >
            {event !== null && (
                <div className="event-content">
                    <button className="close-btn" ></button>
                    <div className="header">
                        <EventIconLabel firstView={event.firstView} eventName={event.eventName} includeLabel={true} />
                    </div>
                    <div className="event" >
                        <div className="base-content" ref={baseRef}>
                            <DisclosureComponent
                                isOpen={disclosureOpen}
                                toggleDisclosure={toggleDisclosure}
                                disclosureItems={disclosureItems}
                            />
                            <div className="title">
                                {event.eventName}
                            </div>
                            {event.monthEvent ? (
                                <div className="month">
                                    <FontAwesomeIcon className="icon" icon={faCalendarDays} />
                                    {`Month of ${moment(event.startLocal).startOf('month').format('MMMM')}`}
                                </div>
                            ) : null}
                            {event.weekEvent ? (
                                <div className="week">
                                    <FontAwesomeIcon className="icon" icon={faCalendarWeek} />
                                    {`${moment(event.startLocal).startOf('week').format('ddd, MMM D')} - ${moment(event.startLocal).endOf('week').format('ddd, MMM D')}`}
                                </div>
                            ) : null}
                            <div className="day">
                                <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                                {event.start ? moment(event.startLocal).format('dddd, MMMM D') : ''}
                            </div>
                            {event.dateTime && event.startLocal ? (
                                <div className="start">
                                    <FontAwesomeIcon className="icon" icon={faClock} />
                                    {moment(event.startLocal).format('h:mm a')}
                                </div>
                            ) : null}
                            {event.dateTime && event.endLocal ? (
                                <div className="end">
                                    {` - ${moment(event.endLocal).format('h:mm a')}`}
                                </div>
                            ) : null}
                            <div className="description" ref={descriptionRef} >
                                {event.description}
                            </div>
                        </div>
                        <div className="action">
                            {event.hyperlink ? (
                                <button className="go" onClick={() => handleOpenLink(event.hyperlink)}>
                                    <span className="label">Go</span><FontAwesomeIcon className="icon" icon={faSquareArrowUpRight} />
                                </button>
                            ) : null}
                            <div className="right-action">
                                <button onClick={handleLikeClick} className={`like ${like ? 'true' : 'false'}`}>
                                    <FontAwesomeIcon icon={faBoltLightning} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sources">
                        <div className="source-messages">
                            {event.mentions.length > 0 ? (
                                event.mentions.map(mention => (
                                    renderMentioners(mention)
                                ))
                            ) : (
                                renderMentioners(event.mentions[0])
                            )}
                        </div>
                        <span className="note">{renderMentionNote(event)}</span>
                    </div>
                </div>
            )}
        </div>
    );

    return renderedContent;
};

export default EventComponent;