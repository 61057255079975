import React, { useMemo, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FeedContext } from './FeedContext';
import { extendMoment } from 'moment-range';
import moment from 'moment';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';
import CheckMessages from './CheckMessages';
import EventIconLabel from './EventIconLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import { faCalendarWeek, faCertificate, faCircleUser } from '@fortawesome/free-solid-svg-icons'

const FocusFeed = ({ openEvent, openMessage }) => {
  const { days, loadComplete, eventCount } = useContext(FeedContext);
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const navRef = useRef(null);
  const feedContainerRef = useRef(null);
  const monthsRef = useRef(null)
  const todayRef = useRef(null);
  const extendedMoment = extendMoment(moment);
  const offset = -66;
  const expandedOffset = -186;

  useEffect(() => {
    setStartTimestamp(Date.now());
    setTimeout(() => {
      scrollToToday();
    }, 500);
  }, []);

  useEffect(() => {
    if (loadComplete) {
      scrollToToday();
    }
  }, [loadComplete]);

  useEffect(() => {
    positionNavMonths();
  }, [eventCount]);

  useEffect(() => {
    window.addEventListener('scroll', positionNavMonths);
    positionNavMonths();

    return () => {
      window.removeEventListener('scroll', positionNavMonths);
    };

  }, [navRef.current, monthsRef.current, feedContainerRef.current, todayRef.current]);

  function positionNavMonths() {
    const nav = navRef.current;
    const months = monthsRef.current;
    const feedContainer = feedContainerRef.current;

    if (!nav || !months || !feedContainer) return;

    const containerHeight = feedContainer.clientHeight;
    const viewportHeight = window.innerHeight;

    if (months.children && months.children.length > 0) {
      // If container is not tall enough to force a scroll, hide all but top month
      if (containerHeight <= viewportHeight) {
        months.children[0].style.opacity = 1;
        return;
      } else if (months.children[0].style.opacity == 0) {
        for (let j = 0; j < months.children.length; j++) {
          months.children[j].style.opacity = 1;
        }
      }
    }

    const monthContainers = feedContainer.querySelectorAll('.month-container');
    const navRect = nav.getBoundingClientRect();
    const monthsRect = months.getBoundingClientRect();
    const maskOffset = monthsRect.top - navRect.top;

    for (let i = 0; i < monthContainers.length; i++) {
      const container = monthContainers[i];
      const feedMonth = container.querySelector('.month-title');

      const restingPoint = 0;
      const monthRect = container.getBoundingClientRect();
      let offsetY = monthRect.top - navRect.top;
      const offsetYPlusHeight = -(monthRect.height) - offsetY + 14;

      if (offsetY < restingPoint && offsetYPlusHeight < restingPoint) {
        offsetY = restingPoint;
        feedMonth.style.opacity = 0;
      } else if (offsetYPlusHeight >= restingPoint) {
        offsetY = -(offsetYPlusHeight);
        feedMonth.style.opacity = 0;
      } else if (offsetY >= restingPoint) {
        feedMonth.style.opacity = 1;
      }

      if (i == 0 && offsetY > 0) {
        offsetY = 0;
      }

      const monthTitle = months.children[i];
      monthTitle.style.transform = `translateY(${offsetY}px)`;
    }
  }


  function scrollToToday(toggleExpand) {
    if (todayRef.current) {

      if (toggleExpand) {
        navRef.current.classList.toggle('expand')
      }

      const isExpanded = navRef.current.classList.contains('expand');

      const element = todayRef.current;

      // Get the element's position relative to the viewport
      const elementRect = element.getBoundingClientRect();

      // Calculate the top position with the desired offset
      const topPosition = elementRect.top + window.scrollY + (isExpanded ? expandedOffset : offset);

      // Scroll to the calculated position
      window.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      });
    }
  }

  const weekContainerRef = useRef(null); // Container div
  const weekTrayRef = useRef(null); // Parent of week divs
  const startXRef = useRef(0); // Starting X position of the touch
  const trayStartXRef = useRef(0); // Initial translateX value of the tray
  const weekContainerWidth = useRef(0); // Width of the week container
  const currentIndexRef = useRef(0); // Keeps track of the current visible week

  const handleTouchStart = (e) => {
    document.body.style.overflow = 'hidden'; // Disable body scroll
    feedContainerRef.current.style.overflowY = 'hidden' //Div with content that scrolls
    document.body.style.touchAction = 'none';
    feedContainerRef.current.style.touchAction = 'none';

    // Capture the starting position of the swipe
    startXRef.current = e.touches[0].clientX;

    // Get the starting translate X from the weekTrayRef
    const matrix = getComputedStyle(weekTrayRef.current).transform;
    trayStartXRef.current = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;

    // Get the width of the weekContainer
    weekContainerWidth.current = weekContainerRef.current.offsetWidth;
  };

  const handleTouchMove = (e) => {
    // Calculate the horizontal swipe distance
    const deltaX = e.touches[0].clientX - startXRef.current;

    // Apply the translateX value based on the swipe movement
    weekTrayRef.current.style.transform = `translateX(${trayStartXRef.current + deltaX}px)`;
  };

  const handleTouchEnd = () => {
    document.body.style.overflow = 'auto'; // Re-enable body scroll
    feedContainerRef.current.style.overflowY = 'scroll'
    document.body.style.touchAction = 'auto';
    feedContainerRef.current.style.touchAction = 'auto';

    // Calculate the current position of the tray
    const matrix = getComputedStyle(weekTrayRef.current).transform;
    const currentTranslateX = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;

    // Calculate the swipe distance as a percentage of the container width
    const swipeDistance = currentTranslateX - trayStartXRef.current;
    const swipePercentage = Math.abs(swipeDistance / weekContainerWidth.current);

    // Check if the swipe is beyond the 20% threshold
    if (swipePercentage > 0.25) {
      if (swipeDistance < 0 && currentIndexRef.current < weekTrayRef.current.children.length - 1) {
        // Swipe left and move to the next week
        currentIndexRef.current++;
      } else if (swipeDistance > 0 && currentIndexRef.current > 0) {
        // Swipe right and move to the previous week
        currentIndexRef.current--;
      }
    }

    // Snap to the calculated week
    const snapPosition = -currentIndexRef.current * weekContainerWidth.current;
    weekTrayRef.current.style.transition = 'transform 0.2s ease-out';
    weekTrayRef.current.style.transform = `translateX(${snapPosition}px)`;

    // Remove the transition after snapping
    weekTrayRef.current.addEventListener(
      'transitionend',
      () => {
        weekTrayRef.current.style.transition = '';
      },
      { once: true }
    );
  };


  function getMomentRange(event) {
    if (!event.dateTime) {
      return null;
    }

    const startMoment = event.startLocal ? extendedMoment(event.startLocal) : null;
    const endMoment = event.endLocal ? extendedMoment(event.endLocal) : null;

    if (startMoment && endMoment) {
      return extendedMoment.range(startMoment, endMoment);
    }

    return startMoment;
  }

  function formatTime(momentTime) {
    if (momentTime.minutes() === 0) {
      return momentTime.format('ha');
    } else {
      return momentTime.format('h:mma');
    }
  }

  function formatMonth(YYYYMM) {
    const checkYear = moment(YYYYMM).format('YYYY');
    const thisYear = moment(startTimestamp).format('YYYY');

    if (YYYYMM) {
      return (YYYYMM.slice(-2) === '01' || YYYYMM.slice(-2) === '12' || checkYear != thisYear) // Year added to months not in current year
        ? moment(YYYYMM).format('MMMM YYYY')
        : moment(YYYYMM).format('MMMM')
    }
  }

  function isThisMonth(date) {
    if (date && startTimestamp) {
      const checkMonth = moment(date).format('YYYY-MM');
      const thisMonth = moment(startTimestamp).format('YYYY-MM');
      return checkMonth.toString() === thisMonth.toString();
    }
    return false;
  }

  const EventTime = ({ event }) => {
    const eventMoment = getMomentRange(event);

    let displayTime = '\u00A0'; // Non-breaking space as a placeholder

    if (eventMoment) {
      if (extendedMoment.isRange(eventMoment)) {
        displayTime = `${formatTime(eventMoment.start)} - ${formatTime(eventMoment.end)}`; // EventMoment Object! Not event object!
      } else {
        displayTime = formatTime(eventMoment);
      }
    }

    return (
      <div className="timing">
        {displayTime}
      </div>
    );
  };

  const renderEventCreators = (event) => {
    if (!event || event.mentions.length == 0) {
      return;
    }

    const initials = getFirstTwoInitials(event.mentions[0].sourceSender);
    const colors = getColors(initials);

    return (
      <span
        className="count"
        style={event.firstView
          ? {
            background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
            border: `1px solid ${colors.secondary}`
          }
          : {}} >
        {initials}
        {event.mentions.length > 1 ? ` +${event.mentions.length - 1}` : ''}
      </span>
    )
  }

  const isToday = (day) => {
    return moment(day).isSame(startTimestamp, 'day') ? 'today' : ''
  }

  const setEventVisibilityClassName = (events, event) => {
    // Check if the event has dislike set to true
    if (event.dislike) {
      return ' dislike'; // Return "dislike" if the event is disliked
    }

    // If there are no related events, return an empty string (visible)
    return '';
  };

  const dayContainer = (day, events) => {
    return (
      <div className="day-container">
        <div
          className={`day ${isToday(day)}`}
          ref={moment(day).isSame(startTimestamp, 'day') ? todayRef : null}
          data-day={moment(day).format('YYYY-MM-DD')}
        >
          <div className='ddd'>{moment(day).format('ddd')}</div>
          <div className='MMDD'>
            <span className='DD'>{moment(day).format('D')}</span>
          </div>
        </div>

        <div className="event-container">
          {events.length > 0 ? (
            events.map((event, index) => (
              <div className={`event${setEventVisibilityClassName(events, event)}${event.firstView ? ' firstview' : ''}`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  openEvent(event._id);
                }}>
                <EventTime event={event} />
                <div className="title">
                  {event.eventName}
                </div>
                <div className='message'>
                  <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); openMessage(event.mentions[0].sourceMessageId); }} href="/">
                    {renderEventCreators(event)}
                  </a>
                </div>
                <EventIconLabel firstView={event.firstView} eventName={event.like ? 'like' : event.eventName} includeLabel={false} />
              </div>
            ))
          ) : (
            <div className="event none">
              <div className="timing" >&nbsp;</div>
              <div className="title">
                No Events Today
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const monthsMap = {};

  const mapMonths = () => {
    // Group days by month
    [...days.entries()].forEach(([day, events]) => {
      const monthKey = moment(day).format('YYYY-MM'); // Group by month
      if (!monthsMap[monthKey]) {
        monthsMap[monthKey] = [];
      }
      monthsMap[monthKey].push({ day, events });
    });

    //console.log(monthsMap)
  }

  const weeksMap = {};

  const mapWeeks = () => {
    // Iterate over each day and its events
    [...days.entries()].forEach(([day, events]) => {
      // Get the week number and year for grouping by week
      const weekKey = moment(day).format('YYYY-WW'); // e.g., "2023-12" for week 12 of 2023

      // If this week doesn't exist in weeksMap, initialize it
      if (!weeksMap[weekKey]) {
        weeksMap[weekKey] = {
          days: Array(7).fill(null).map((_, i) => ({
            date: moment(day).startOf('week').add(i, 'days').format('YYYY-MM-DD'),
            hasEvent: false
          })),
          weekEvents: []
        };
      }

      // Find the day index within the week and mark it as having an event
      const dayIndex = moment(day).weekday(); // 0 for Sunday, 6 for Saturday
      weeksMap[weekKey].days[dayIndex].hasEvent = true;

      // Add all events with weekEvent property to weekEvents array
      const weekEvents = events.filter(event => event.weekEvent);
      if (weekEvents.length) {
        weeksMap[weekKey].weekEvents.push(...weekEvents);
      }
    });

    //console.log(weeksMap);
  };

  const renderWeeks = () => {
    return Object.keys(weeksMap).map((weekKey) => {
      const week = weeksMap[weekKey]; // Get the week object
      return (
        <div className='week-details'>
          <div className="week" key={weekKey} data-week={weekKey}>
            {week.days.map((day) => (
              <div
                key={day.date}
                className={`day ${day.hasEvent ? 'events' : ''}`}
                data-day={day.date}
              >
                <span className="label">
                  {moment(day.date).format('ddd').toLowerCase()}
                </span>
                <div className={`date ${isToday(day.date)}`}>
                  {moment(day.date).format('D')}
                </div>
              </div>
            ))}
          </div>
          <div className='week-events-container'>
              <FontAwesomeIcon className="icon" icon={faCalendarWeek} />
              <span class='label'>Week Events</span>
            </div>
        </div>
      );
    });
  };


  const renderMonths = () => {
    return Object.keys(monthsMap).map((monthKey, index) => (
      <div key={index} className={isThisMonth(monthKey) ? 'month-title today' : 'month-title'}>
        {formatMonth(monthKey)}
      </div>
    ));
  }

  const renderDaysByMonth = () => {
    // Render the days grouped by month
    return Object.keys(monthsMap).map((monthKey, index) => (
      <div className="month-container" data-month={monthKey} key={monthKey}>
        <div className={isThisMonth(monthKey) ? 'month-title today' : 'month-title'}>
          {formatMonth(monthKey)}
        </div>
        {monthsMap[monthKey].map(({ day, events }) => (
          <React.Fragment key={day}>
            {dayContainer(day, events)}
          </React.Fragment>
        ))}
      </div>
    ));
  };

  const renderEvents = useCallback(() => {
    mapMonths();
    mapWeeks();

    return (
      <div>
        <div className='nav' ref={navRef}>
          <div className='top'>
            <button className='go-today' onClick={(e) => { scrollToToday(true) }}>
              <FontAwesomeIcon className="icon" icon={faCalendarCheck} />
              <span className='label'>Today</span>
            </button>
            <div className='months-container' ref={monthsRef}>
              {renderMonths()}
            </div>
            <Link className='profile' to="/profile">
              <button>
                <FontAwesomeIcon className="icon" icon={faCircleUser} />
              </button>
            </Link>
          </div>
          <div className='divider'></div>
          <div className='bottom'>
            <div className='week-container'
              ref={weekContainerRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div className='week-tray'
                ref={weekTrayRef}
              >
                {renderWeeks()}
              </div>
            </div>
          </div>
        </div>
        <CheckMessages />
        <div ref={feedContainerRef} className="feed-container">
          {renderDaysByMonth()}
        </div>
      </div>
    );
  }, [days, startTimestamp, currentMonth]);

  // Memoize the rendering of events to prevent unnecessary re-renders
  const renderedEvents = useMemo(() => {
    if (days) {
      return renderEvents();
    } else {
      return <p>Loading...</p>;
    }
  }, [days, renderEvents]);

  return <div>{renderedEvents}</div>;
};

export default FocusFeed;
