import React, { createContext, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { formatEventDate } from '../utils/FormatEventDate';

// Create a context
const FeedContext = createContext();

const FeedProvider = ({ children }) => {
  const firstRunRef = useRef(false);
  const [events, setEvents] = useState(null); // Events should be set to NULL, otherwise this breaks loading cycle. TODO: FIX THIS.
  const [error, setError] = useState(null);
  const [days, setDays] = useState(new Map());
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [eventCount, setEventCount] = useState(0);

  const eventSourceRef = useRef(null);
  const [ESloadingState, setESLoadingState] = useState('idle');
  const [loadComplete, setLoadComplete] = useState(false);

  const updateEvent = (updatedEvent) => {
    const eventToUpdate = events.get(updatedEvent._id);
    if (eventToUpdate) {
      // Directly update the properties of the existing event
      Object.assign(eventToUpdate, updatedEvent);

      // Update the events state with the modified Map
      setEvents(new Map(events));
    }
  };


  useEffect(() => {
    if (ESloadingState == 'complete'
      && events
      && events.size > 0
      && events.size == eventCount) {

      setLoadComplete(true);
    }
  }, [ESloadingState, events, eventCount]);


  useEffect(() => { // Check Messages when component mounts.
    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource('/api/checkmessages');

      const onopen = () => {
        setESLoadingState('loading'); // Indicate that SSE connection is open
      };

      const onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (firstRunRef.current && data.currentProgress > 0 && data.eventsCount > 0) { // First run, so show first set of messages found
          firstRunRef.current = false;
          fetchEvents(); // Grab EVENTS on first set of events parsed.
        }

        if (data.currentProgress < data.totalProgress) { // Messages
          setESLoadingState('progress');

        } else if (data.currentProgress === data.totalProgress) { // all messages checked
          eventSourceRef.current.close();
          setESLoadingState('complete');

          if (data.eventsCount > 0) {
            fetchEvents(); // Grab EVENTS after all events have been parsed.
          }
        }

      };

      const onerror = () => {
        if (eventSourceRef.current.readyState === EventSource.CLOSED) {
          setESLoadingState('closed');
          eventSourceRef.current.close();
        } else if (eventSourceRef.current.readyState === EventSource.CONNECTING) {
          setESLoadingState('loading');
        } else {
          setESLoadingState('error');
        }
      };

      eventSourceRef.current.addEventListener('open', onopen);
      eventSourceRef.current.addEventListener('message', onmessage);
      eventSourceRef.current.addEventListener('error', onerror);

      // Cleanup function
      return () => {
        if (eventSourceRef.current && eventSourceRef.current.readyState !== EventSource.CLOSED) {
          eventSourceRef.current.removeEventListener('open', onopen);
          eventSourceRef.current.removeEventListener('message', onmessage);
          eventSourceRef.current.removeEventListener('error', onerror);

          eventSourceRef.current.close();
          eventSourceRef.current = null;
        }
      };
    }
  }, []);

  const addLocalDateAndSetEvents = (eventsMap) => {
    eventsMap.forEach(function (event, eventId) {
      event.startLocal = formatEventDate(event.start, event.dateTime);
      if (event.end) { event.endLocal = formatEventDate(event.end, event.dateTime) }
    });

    setEvents(eventsMap);
  }

  const abortController = useRef(new AbortController());

  const fetchEvents = async () => {
    abortController.current.abort(); // Abort any ongoing requests
    abortController.current = new AbortController();

    try {
      // Clear previous data before fetching new data
      setEvents(null); // Events should be set to NULL, otherwise this breaks loading cycle. TODO: FIX THIS.
      setError(null);
      setDays(new Map());
      setEventCount(0); // Set event count to 0
      setStartTimestamp(Date.now());

      const eventsResponse = await fetch('/api/events', { signal: abortController.current.signal }); // Pass signal to fetch

      if (eventsResponse.ok) {
        const eventsData = await eventsResponse.json();
        const eventsArray = eventsData.events;
        const eventsMap = new Map(eventsArray.map(event => [event._id, event]));

        addLocalDateAndSetEvents(eventsMap);

      } else if (eventsResponse.status === 401) {
        window.location.href = '/'; // Redirect to login page

      } else {
        console.error('Error:', eventsResponse.status, eventsResponse.statusText);

      }

    } catch (error) {
      setError(error);
    }
  };

  // Grabs all the events when component mounts.
  useEffect(() => {
    fetchEvents();

    return () => {  // Cleanup function
      abortController.current.abort(); // Abort the API request if the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (events) {

      setEventCount(events.size);

      if (events.size > 0) {
        let eventsToday = false;
        const daysMap = new Map();

        // Organize events into days
        events.forEach(function (event, eventId) {
          const day = moment(event.startLocal).format('YYYY-MM-DD');

          if (moment(day).isSame(moment(), 'day')) {
            eventsToday = true; // There are events for today.
          }

          if (!daysMap.has(day)) {
            daysMap.set(day, []);
          }

          daysMap.get(day).push(event);
        });

        if (!eventsToday) { // Create an empty day if no events for today.
          const today = moment().format('YYYY-MM-DD');
          daysMap.set(today, []);
        }

        // Extract individual events and update events state
        setDays((prevDays) => {
          const updatedDays = new Map(prevDays);

          // Loop through each day in the new data
          daysMap.forEach((newEvents, day) => {
            const existingEvents = updatedDays.get(day) || [];

            // Use a Set to ensure events are unique based on _id
            const uniqueEvents = new Map();

            // Add existing events to the Map, but filter out disliked events
            existingEvents
              .filter(event => !event.dislike) // Remove events where dislike is true
              .forEach(event => uniqueEvents.set(event._id, event));

            // Add new events to the Map, also filtering out disliked events
            newEvents
              .filter(event => !event.dislike) // Remove events where dislike is true
              .forEach(event => uniqueEvents.set(event._id, event));

            // Update the day with unique events as an array
            const filteredEvents = Array.from(uniqueEvents.values());

            // Only update if there are events for the day
            if (filteredEvents.length > 0 || moment(day).isSame(moment(), 'day')) {
              updatedDays.set(day, filteredEvents);
            } else {
              updatedDays.delete(day);
            }
          });

          // Sort the events of the day by start timestamp
          updatedDays.forEach((eventsList, day) => {
            eventsList.sort((a, b) => {
              if (a.dateTime && !b.dateTime) {
                return -1;
              } else if (!a.dateTime && b.dateTime) {
                return 1;
              } else {
                return moment(a.start).valueOf() - moment(b.start).valueOf();
              }
            });
          });

          // Return the updated and sorted days object
          return new Map([...updatedDays.entries()].sort());
        });
      } else if (events.size === 0) {
        firstRunRef.current = true; // First run.
      }
    }
  }, [events]);





  return (
    <FeedContext.Provider value={{ events, days, error, eventCount, eventSourceRef, ESloadingState, loadComplete, updateEvent, fetchEvents }}>
      {children}
    </FeedContext.Provider>
  );
}

export { FeedProvider, FeedContext };
