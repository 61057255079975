import React, { useRef, useEffect } from 'react';

const CircleProgress = ({ percentage, size }) => {
  const strokeWidth = 3; // Adjust stroke width for smaller size
  const radius = (size / 2) - (strokeWidth / 2); // Adjust radius
  const strokeDasharray = Math.PI * 2 * radius;
  const strokeDashoffset = strokeDasharray - (percentage / 100) * strokeDasharray;

  return (
    <div className="progress-container">
      <svg className="indicator" width={size} height={size}>
        <circle
          className="background-circle"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="progress-circle"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </div>
  );
};

export default CircleProgress;