import moment from 'moment';

const formatEventDate = (dateString, hasTime) => {
    const utcDate = moment.utc(dateString);
    if (hasTime) {
        // For events with time precision, convert to local time
        return utcDate.local().format('YYYY-MM-DDTHH:mm:ss');
    } else {
        // For day-level precision events, keep the date as is
        return utcDate.format('YYYY-MM-DD');
    }
}

export { formatEventDate }
