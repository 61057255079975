import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

const WatchlistComponent = ({ onAdvanceChange }) => {
  const [address, setAddress] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [advance, setAdvance] = useState(false);

  const buildAddressList = async () => {
    try {
      const [addressesResponse, watchlistResponse] = await Promise.all([
        fetch('/api/addresses'),
        fetch('/api/watchlist')
      ]);

      if (addressesResponse.ok && watchlistResponse.ok) {
        const addressesData = await addressesResponse.json();
        const watchlistData = await watchlistResponse.json();

        const addressList = [];
        addressesData.addresses.forEach(item => {
          item.watchlist = false;
          item.suggested = true;
          addressList.push(item)
        });

        setAdvance(false);

        watchlistData.watchlist.forEach(address => {
          const addressExists = addressesData.addresses.some(item => item.address.toLowerCase() === address.toLowerCase());

          if (addressExists) {
            const matchingItem = addressList.find(item => item.address.toLowerCase() === address.toLowerCase());
            if (matchingItem) {
              matchingItem.watchlist = true;
              setAdvance(true);
            }
          } else {
            addressList.push({ address: address, watchlist: true });
            setAdvance(true);
          }
        });

        setAddresses(addressList);
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    onAdvanceChange(advance);
  }, [advance, onAdvanceChange]);

  const requestWatchlistUpdate = async (address, keep) => {
    const method = keep ? 'POST' : 'DELETE';

    try {
      const response = await fetch('/api/watchlist', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      });

      if (response.ok) {
        console.log('Email address updated successfully');
        // Optionally, you can reset the input field
        setAddress('');
        buildAddressList();
      } else {
        console.error('Failed to update email address');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    buildAddressList();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (address.length === 0 || address.split('.').length < 2) {
      return;
    }

    requestWatchlistUpdate(address, true);
  };

  const handleChange = (event) => {
    const { id, checked } = event.target;
    requestWatchlistUpdate(id, checked);
  };

  const handleClear = () => {
    setAddress('');
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input className="address-input" type="text" inputMode="email" autoComplete="email" placeholder="Enter email address or domain" value={address} onChange={(e) => setAddress(e.target.value)} />
        {address && (
          <button type="button" className="clear" onClick={handleClear}>
            <FontAwesomeIcon className="icon" icon={faCircleXmark} />
          </button>
        )}
        <button className="right-button" type="submit">Add</button>
      </form>
      <br />
      <div className='address-list-container'>
        <div className='address-list'>
          {addresses.map((item, index) => (
            <div key={index}>
              <input type="checkbox" id={item.address} checked={item.watchlist} onChange={handleChange} />
              <label htmlFor={index}>{item.address} {item.suggested && <span className='note'>(recommended)</span>}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WatchlistComponent;
