import React, { useState, useEffect } from 'react';
import WatchlistComponent from '../components/WatchlistComponent';

const JoinEmail = () => {
  const [advance, setAdvance] = useState(false);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };

  async function saveUserTimezone() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const response = await fetch('/api/timezone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ timezone })
    });

    if (response.ok) {
      console.log('Timezone saved successfully');
    } else {
      console.error('Failed to save timezone');
    }
  }

  useEffect(() => {
    saveUserTimezone();
  }, []);  // runs once


  return (
    <div className="join-email">
      <h2>Simple Schedule needs Email Access</h2>
      <div className='explain'>Your schedule is created <br/>from the email messages sent to you</div>
      <button className='gmail-button' onClick={() => { window.location.href = '/api/auth/google/gmail' }}>Allow Access</button>
      <div className='note'>We care deeply about your privacy. You will always have the ability<br/> to delete your <a href="/profile">data</a>, <a href="/profile">account</a>, or <a href="https://myaccount.google.com/permissions" target="_blank" rel="noreferrer">revoke</a> access at anytime.</div>
    </div>
  );
};

export default JoinEmail;
