import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment'
import axios from 'axios';
import { extendMoment } from 'moment-range';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeedContext } from './FeedContext';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faBugSlash, faCalendarDay, faEllipsis, faEnvelopeOpenText, faExpand, faSpinner, faCircleNotch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { formatEventDate } from '../utils/FormatEventDate';
import DisclosureComponent from './DisclosureComponent';

import DOMPurify from 'dompurify';
import '../styles/MessageComponent.css'

const MessageComponent = ({ messageId, closeMessage, openEvent }) => {
    //const { id } = useParams(); // Extract id from URL params
    const { fetchEvents } = useContext(FeedContext);
    const [messageData, setMessageData] = useState(null);
    const [html, setHtml] = useState(null);
    const [sender, setSender] = useState('');
    const [events, setEvents] = useState([]);
    const [eventCount, setEventCount] = useState(0);
    const [drawerLoaded, setDrawerLoaded] = useState(false);
    const [disclosureOpen, setDisclosureOpen] = useState(null);
    const [icon, setIcon] = useState(faExpand); // Initially set to faExpand
    const extendedMoment = extendMoment(moment);
    const rescanButtonRef = useRef(null);
    const drawerRef = useRef(null);
    const eventHScrollerRef = useRef(null);
    const messageVScrollerRef = useRef(null);
    const shadowRef = useRef(null);
    const isDraggingRef = useRef(false);
    const startYRef = useRef(0);
    const currentYRef = useRef(0);
    const drawerHeight = useRef(0);


    // Function to add a meta tag for responsiveness
    const addHeadTags = (html) => {
        const headTags = `
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Security-Policy" content="script-src 'none'" />
        <base target="_blank" />
        <style>
        table {
        max-width: 100% !important;
        width: 100% !important; /* Use width instead of max-width */
        height: auto !important;
        }
        img, div {
        max-width: 100% !important;
        height: auto !important;
        }
        iframe {
        overflow: hidden; /* or auto depending on your needs */
        width: 100%;
        height: auto; /* Make sure height adjusts based on content */
        border: none; /* Remove default border */
        }
        body {
        margin: 0; /* Remove default margin */
        padding: 0; /* Remove default padding */
        overflow-x: hidden; /* Prevent horizontal scrolling */
        width: 100vw; /* Ensure body width covers the viewport width */
        }
        </style>
        `;

        // Check if <head> exists, if not add one with the meta tag
        if (html.includes('<head>')) {
            // Inject the meta tag into the existing <head> section
            return html.replace('<head>', `<head>${headTags}`);
        } else {
            // Add <head> with meta tag before the <body> tag
            return `<head>${headTags}</head>` + html;
        }
    };

    const closeDrawer = () => {
        drawerRef.current.style.bottom = '-100%'; // Hide drawer
        shadowRef.current.style.opacity = 0;
        setTimeout(() => {
            closeMessage(); // Close after transition is complete
        }, 501);
    }

    const toggleDisclosure = (openState) => setDisclosureOpen(openState);

    const rescanMessage = async () => {
        setIcon(faCircleNotch);

        if (rescanButtonRef.current) {
            rescanButtonRef.current.classList.add('active'); // Example class toggle
        }

        shadowRef.current.style.transition = 'opacity 0.5s ease-in-out'; // Ensure transition is set
        shadowRef.current.style.opacity = 0; // Smooth transition to 0 opacity

        try {
            await axios.post(`/api/messages/${messageId}/reset`);
            await fetchEvents();
            setIcon(faExpand);
            setDisclosureOpen(false);
            reinitializeComponent();

        } catch (error) {
            console.error('Failed to rescan message:', error);
        }

        if (rescanButtonRef.current) rescanButtonRef.current.classList.remove('active');
    };

    const shareWithDeveloper = () => {
        console.log('share with dev');
        setDisclosureOpen(false);

        const userConfirmed = window.confirm("By pressing 'OK' you agree to share this email with the developer to help improve this product.");
    }

    const disclosureItems = [
        { label: 'Rescan Message', icon: icon, onClick: rescanMessage, ref: rescanButtonRef },
        { label: 'Share with Developer', icon: faBugSlash, onClick: shareWithDeveloper }
    ];

    // Fetch data function
    const fetchData = async (controller) => {
        const { signal } = controller;

        try {
            const [messageResponse, eventsResponse] = await Promise.all([
                fetch(`/api/messages/${messageId}`, { signal }), // Use id from URL params
                fetch(`/api/messages/${messageId}/events`, { signal }) // Use id from URL params
            ]);

            const messageData = await messageResponse.json();
            setMessageData(messageData);
            setSender(messageData.senderInfo.sender);

            if (messageData.html) {
                const sanitizedHtml = DOMPurify.sanitize(messageData.html);
                setHtml(addHeadTags(sanitizedHtml));
            }

            const eventsData = await eventsResponse.json();

            eventsData.events.forEach(event => {
                if (event.start) event.start = formatEventDate(event.start, event.dateTime);
                if (event.end) event.end = formatEventDate(event.end, event.dateTime);
            });

            const sortedEvents = eventsData.events.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });

            setEvents(sortedEvents);
            setEventCount(sortedEvents.length);
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request aborted:', error.message);
            } else {
                console.error('Error fetching data:', error);
            }
        }
    };

    // Drawer handling logic
    const initializeDrawer = () => {
        const drawer = drawerRef.current;
        const shadow = shadowRef.current;
        drawerHeight.current = parseFloat(getComputedStyle(drawerRef.current).height);

        const handleTouchStart = (e) => {
            drawer.style.animation = 'none'; // Clear animation when starting the drag
            drawer.style.transition = 'none'; // Remove the transition to avoid interference with other animations or drags
            startYRef.current = currentYRef.current = e.touches[0].clientY; // Use ref to store startY
            isDraggingRef.current = true;
        };

        const handleTouchMove = (e) => {
            if (isDraggingRef.current) {
                e.preventDefault();

                const diffY = e.touches[0].clientY - currentYRef.current;

                const drawerBottom = parseFloat(getComputedStyle(drawerRef.current).bottom);
                const setY = drawerBottom - diffY;

                if (setY <= 0) {
                    drawer.style.bottom = `${setY}px`;
                    currentYRef.current = e.touches[0].clientY;
                } else {
                    drawer.style.bottom = `4px`;
                }

                const opacity = (drawerHeight.current + drawerBottom) / drawerHeight.current;
                if (shadowRef.current) {
                    shadowRef.current.style.opacity = opacity;
                }
            }
        };

        const handleTouchEnd = () => {
            isDraggingRef.current = false;
            const drawerBottom = Math.abs(parseFloat(getComputedStyle(drawer).bottom));

            // Add a smooth transition for the bottom property
            drawer.style.transition = 'bottom 0.15s ease-in-out';

            if (drawerBottom > drawer.getBoundingClientRect().height / 4) {
                closeDrawer();
            } else {
                drawer.style.bottom = '4px'; // Reset position
            }
        };

        // Exclude dragging within scrollable areas
        const preventDrawerDrag = (e) => {
            e.stopPropagation();  // Prevent the event from bubbling up
        };

        // Attach event listeners
        drawer.addEventListener('touchstart', handleTouchStart);
        drawer.addEventListener('touchmove', handleTouchMove);
        drawer.addEventListener('touchend', handleTouchEnd);

        eventHScrollerRef.current.addEventListener('touchstart', preventDrawerDrag);
        messageVScrollerRef.current.addEventListener('touchstart', preventDrawerDrag);

        setDrawerLoaded(true);

        return () => {
            drawer.removeEventListener('touchstart', handleTouchStart);
            drawer.removeEventListener('touchmove', handleTouchMove);
            drawer.removeEventListener('touchend', handleTouchEnd);

            if (eventHScrollerRef.current) {
                eventHScrollerRef.current.removeEventListener('touchstart', preventDrawerDrag);
            }

            if (messageVScrollerRef.current) {
                messageVScrollerRef.current.removeEventListener('touchstart', preventDrawerDrag);
            }
        };
    };

    // Function to reinitialize the component
    const reinitializeComponent = () => {
        drawerRef.current.style.bottom = '-100%'; // Hide drawer

        setTimeout(() => {
            drawerRef.current.classList.remove('message-loaded');
            shadowRef.current.classList.remove('message-loaded');

            setMessageData(null)
            setEvents([]);
            setEventCount(0);
            const controller = new AbortController(); // Create a new AbortController
            fetchData(controller);
            initializeDrawer();

        }, 501);

    };

    // useEffect for initial load
    useEffect(() => {

        const controller = new AbortController();

        // Fetch data and initialize drawer
        fetchData(controller);
        const cleanupDrawer = initializeDrawer();

        document.body.style.overflow = 'hidden'; // Disable body scroll

        return () => {
            controller.abort(); // Abort any ongoing requests
            document.body.style.overflow = 'auto'; // Re-enable body scroll
            cleanupDrawer();
        };
    }, [messageId]);


    useEffect(() => {
        if (drawerRef.current && drawerLoaded && messageData) {
            drawerRef.current.style.bottom = '4px'; // Reset position
            shadowRef.current.style.opacity = 1;
            drawerRef.current.classList.add('message-loaded');
            shadowRef.current.classList.add('message-loaded');
        }
    }, [drawerLoaded, messageData, drawerRef.current]);

    function formatTime(momentTime) {
        if (momentTime.minutes() === 0) {
            return momentTime.format('ha');
        } else {
            return momentTime.format('h:mma');
        }
    }

    function getMomentRange(event) {
        if (!event.dateTime) {
            return null;
        }

        const startMoment = event.start ? extendedMoment(event.start) : null;
        const endMoment = event.end ? extendedMoment(event.end) : null;

        if (startMoment && endMoment) {
            return extendedMoment.range(startMoment, endMoment);
        }

        return startMoment;
    }

    const EventTime = ({ event }) => {
        const eventMoment = getMomentRange(event);

        let displayTime = '\u00A0'; // Non-breaking space as a placeholder

        if (eventMoment) {
            if (extendedMoment.isRange(eventMoment)) {
                displayTime = `${formatTime(eventMoment.start)} - ${formatTime(eventMoment.end)}`; // EventMoment Object! Not event object!
            } else {
                displayTime = formatTime(eventMoment);
            }
        }

        return (
            <div className="timing">
                {displayTime}
            </div>
        );
    };


    const renderProfileColors = (sender) => {
        const firstTwo = getFirstTwoInitials(sender);
        const colors = getColors(firstTwo);

        return {
            background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
            border: `0.1rem solid ${colors.secondary}`
        };
    };

    const renderedContent = (
        <div className="message-modal">
            <div className="message-content" ref={drawerRef}>
                <button className='close-handle' />
                <DisclosureComponent
                    isOpen={disclosureOpen}
                    toggleDisclosure={toggleDisclosure}
                    disclosureItems={disclosureItems}
                    iconRefs={[rescanButtonRef]}
                />
                <button className="close-btn" onClick={closeDrawer}><FontAwesomeIcon icon={faXmark} /></button>
                <div className="message-events">
                    <span className="note">{eventCount} event{eventCount > 1 ? 's' : ''} in this message</span>
                    <div className="event-list" ref={eventHScrollerRef}>
                        {events.map((event, index) => (
                            <div className="event" key={index} onClick={(e) => { e.preventDefault(); openEvent(event._id); }}>
                                <div className="date">
                                    {moment(event.start).format('MMM D')}
                                </div>
                                <div className="title">
                                    {event.eventName}
                                </div>
                                <EventTime event={event} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="message-sender">
                    <div className="profile" style={renderProfileColors(sender)}>
                        <FontAwesomeIcon className="icon" icon={faUser} />
                    </div>
                    {sender}
                </div>
                <div className="message-subject">
                    <FontAwesomeIcon className="icon" icon={faEnvelopeOpenText} />
                    {messageData ? messageData.subject : null}
                </div>
                <div className="message-date">
                    <FontAwesomeIcon className="icon" icon={faCalendarDay} />
                    {messageData ? moment(messageData.creationDate).format('MMM D') : null}
                </div>
                <div className="message-body" ref={messageVScrollerRef}>
                    <iframe
                        title={messageData ? messageData.subject : null}
                        srcDoc={html}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin"
                        csp="script-src 'none'"
                    />
                </div>
            </div>
            <div className='shadow' ref={shadowRef}></div>
        </div>
    );


    return renderedContent;
};

export default MessageComponent;
