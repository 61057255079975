import React, { useEffect, useState } from 'react';
import WatchlistComponent from '../components/WatchlistComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faPersonThroughWindow, faTrashAlt, faCircleXmark, faPowerOff, faBoltLightning, faCircleChevronLeft, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import '../styles/Profile.css'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';

const Profile = () => {
    const [advance, setAdvance] = useState(false);
    const [profile, setProfile] = useState(null);
    const [events, setEvents] = useState(null);
    const [totalEvents, setTotalEvents] = useState(0);
    const [address, setAddress] = useState('');
    const [gcalConnected, setGcalConnected] = useState(false);

    const handleAdvanceChange = (newAdvance) => {
        setAdvance(newAdvance);
        // If false, do not let user exit profile.
    };

    const fetchProfileData = async () => {

        try {
            const [profileResponse, eventsResponse] = await Promise.all([
                fetch('/api/user/profile'),
                fetch('/api/events')
            ]);

            if (profileResponse.ok && eventsResponse.ok) {
                const profileData = await profileResponse.json();
                const eventsData = await eventsResponse.json();

                setProfile(profileData.user);
                setEvents(eventsData.events);

                if (profileData.user) {

                    if (profileData.user.calendarProvider) {
                        const isGCalConnected = profileData.user.calendarProvider == 'gcal';
                        setGcalConnected(isGCalConnected);
                    }

                    if (profileData.user.preferences && profileData.user.preferences.calendarInvitee) {
                        setAddress(profileData.user.preferences.calendarInvitee);
                    }
                }

                if (eventsData.events) {
                    setTotalEvents(eventsData.events.length);
                }

            } else {
                console.error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDeleteClick = () => {
        const userConfirmed = window.confirm('Are you sure you want to delete your account?');

        if (userConfirmed) {
            fetch('/api/user/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        window.location.href = '/';
                    } else {
                        // Handle errors here
                        console.error('Account deletion failed');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleDeleteDataClick = () => {
        const userConfirmed = window.confirm('Are you sure you want to delete your data?');

        if (userConfirmed) {
            fetch('/api/data/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.ok) {
                        window.confirm('All your data has been deleted.');
                    } else {
                        // Handle errors here
                        console.error('Data deletion failed');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const requestUserPreferencesUpdate = async (address) => {

        const preferences = {}
        preferences.calendarInvitee = address;

        try {
            const response = await fetch('/api/user/preferences', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ preferences }),
            });

            if (response.ok) {
                console.log('User Preferences: calendar invite updated successfully');
            } else {
                console.error('Failed to update user preferences');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (address.length === 0 || address.split('.').length < 2) {
            return;
        }

        requestUserPreferencesUpdate(address);
    };

    const handleClear = () => {
        setAddress('');
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    const renderedContent = (profile !== null && events !== null) && (
        <div className='profile'>
            <div className='back' disabled={!advance} onClick={() => { window.location.href = '/focus' }}><FontAwesomeIcon className='icon' icon={faCircleChevronLeft} /> Back to Schedule</div>
            <div className='settings-container'>
                <h2>{profile.displayName}</h2>
                <div className='benefits'>
                    You have saved <span className='savings'>{totalEvents}</span> minutes this month <br />using Simple Schedule.
                </div>
                <div className='title'><FontAwesomeIcon className='icon' icon={faAddressBook} />Addresses</div>
                <div className='watchlist'>
                    <WatchlistComponent onAdvanceChange={handleAdvanceChange} />
                </div>
                <div className='note'>
                    Your schedule is created from only the addresses you specify.
                </div>
                <div className='title'><FontAwesomeIcon className='icon' icon={faBoltLightning} />Syncing</div>
                <div className='settings sync'>
                    <button className='gcal' onClick={() => { window.location.href = '/join/calendar' }}>Google Calendar
                        <span className={`status ${gcalConnected ? "connected" : "connect"}`}>
                            {gcalConnected ? 'Connected' : 'Connect'}
                        </span>
                    </button>
                    {gcalConnected && (
                        <>
                            <div className='title'>
                                <FontAwesomeIcon className='icon' icon={faUserPlus} />Calendar Invitee
                            </div>
                            <form onSubmit={handleSubmit}>
                                <input className="invitee-input" type="email" inputMode="email" autoComplete="email" placeholder="Enter email address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                {address && (
                                    <button type="button" className="clear" onClick={handleClear}>
                                        <FontAwesomeIcon className="icon" icon={faCircleXmark} />
                                    </button>
                                )}
                                <button className="right-button" type="submit">Save</button>
                            </form>
                        </>
                    )}
                </div>
                <div className='note'>
                    Connect your calendar and tap the <FontAwesomeIcon className='icon' icon={faBoltLightning} /> button to sync an event from your schedule to your calendar.
                </div>
                <div className='note'>
                    Invite someone else to all your calendar events by saving an address.
                </div>
                <div className='title'><FontAwesomeIcon className='icon' icon={faPowerOff} />Disconnect</div>
                <div className='settings disconnect'>
                    <button className='logout' onClick={() => { window.location.href = '/api/logout' }}><FontAwesomeIcon className='icon' icon={faRightFromBracket} /> Log Out</button>
                    <button className='delete' onClick={handleDeleteDataClick} ><FontAwesomeIcon className='icon' icon={faTrashAlt} /> Delete Data</button>
                    <button className='delete' onClick={handleDeleteClick} ><FontAwesomeIcon className='icon' icon={faPersonThroughWindow} /> Delete Account</button>
                </div>
                <div className='note'>
                    Deleting your data or account cannot be undone. Events saved to your external calendar are not removed.
                </div>
            </div>
        </div>
    );

    return renderedContent;
};

export default Profile;