import React, { useState, useEffect } from 'react';

const JoinCalendar = () => {

  return (
    <div className="join-calendar">
      <h2>Simple Schedule needs Calendar Access</h2>
      <div className='explain'>Connect to save important events to your calendar</div>
      <button className='gcal-button' onClick={() => { window.location.href = '/api/auth/google/gcal' }}>Allow Access</button>
      <a className='back' href="/profile">Go Back</a>
      <br/>
      <br/>
      <div className='note'>We care deeply about your privacy. <br/>Delete your <a href="/profile">data</a>, <a href="/profile">account</a>, or <a href="https://myaccount.google.com/permissions" target="_blank" rel="noreferrer">revoke</a> access anytime.</div>
    </div>
  );
};

export default JoinCalendar;
