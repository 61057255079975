import { Outlet } from 'react-router-dom';
import { FeedProvider } from './FeedContext';

const FeedContextLayout = () => {

  return (
    <FeedProvider>
      <Outlet />
    </FeedProvider>
  );
};

export default FeedContextLayout;