import React from 'react';
import { motion } from 'framer-motion';

const SuccessIcon = ({ size = 24, color = 'green' }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    initial={{ scale: 0.5, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }}
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M9 12l2 2 4-4" className="checkmark" />
  </motion.svg>
);

export default SuccessIcon;