import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import '../styles/DisclosureComponent.css';

const DisclosureComponent = ({ isOpen, toggleDisclosure, disclosureItems, iconRefs }) => {
    const disclosureItemsRef = useRef(null);
    const disclosureBtnRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                disclosureItemsRef.current &&
                !disclosureItemsRef.current.contains(event.target) &&
                !disclosureBtnRef.current.contains(event.target)
            ) {
                toggleDisclosure(false); // Close disclosure when clicking outside
            }
        };

        document.addEventListener('touchstart', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`disclosure ${isOpen ? 'open' : 'closed'}`}>
            <button className="disclosure-btn" onClick={() => toggleDisclosure(!isOpen)} ref={disclosureBtnRef}>
                <FontAwesomeIcon icon={faEllipsis} />
            </button>
            <div className="items" ref={disclosureItemsRef}>
                {disclosureItems.map((item, index) => (
                    <button
                        key={index}
                        className="item"
                        onClick={item.onClick}
                        ref={iconRefs?.[index] || null}
                    >
                        <FontAwesomeIcon className="icon" icon={item.icon} />
                        {item.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default DisclosureComponent;