import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarRegular, faCalendarCheck, faCalendarPlus, faCalendarXmark, faSquareCheck, faFlag, faHourglass } from '@fortawesome/free-regular-svg-icons'
import { faAward, faBan, faBaseball, faBasketball, faBirthdayCake, faBoltLightning, faBook, faBowlingBall, faBusSimple, faCameraRetro, faCheckDouble, faChess, faClipboardList, faDrum, faFlask, faFootball, faGhost, faGolfBall, faGraduationCap, faHeart, faHighlighter, faHockeyPuck, faMusic, faNewspaper, faPersonBiking, faPersonChalkboard, faPersonDigging, faPersonRunning, faPersonWalking, faSchool, faSchoolLock, faSkiing, faSnowboarding, faSoccerBall, faSquare, faStar, faStopwatch, faTableTennisPaddleBall, faTriangleExclamation, faUmbrella, faUmbrellaBeach, faUserNinja, faUtensils, faVolleyball } from '@fortawesome/free-solid-svg-icons';

function addColor(color) {
    if (_firstView) {
        return { color: color };
    }

    return null;
}

let _firstView = false;

const EventIconLabel = ({ firstView, eventName, includeLabel }) => {
    _firstView = firstView;
    let icon, label;
    const eventNameLC = eventName.toLowerCase();

    switch (true) {
        case eventName == 'like':
            icon = <FontAwesomeIcon className="icon like" icon={faBoltLightning} />;
            label = 'Heart';
            break;
        case eventNameLC.includes(' due'):
        case eventNameLC.includes('deadline'):
        case eventNameLC.includes('due date'):
            icon = <FontAwesomeIcon className="icon important triangle" icon={faTriangleExclamation} />;
            label = 'Due Date';
            break;
        case eventNameLC.includes('register'):
            icon = <FontAwesomeIcon className="icon important" icon={faSquareCheck} />;
            label = 'Registration';
            break;
        case eventNameLC.includes('cancel'):
            icon = <FontAwesomeIcon className="icon" icon={faCalendarXmark} />;
            label = 'Cancelled Event';
            break;
        case eventNameLC.includes('reschedule'):
            icon = <FontAwesomeIcon className="icon" icon={faCalendarPlus} />;
            label = 'Rescheduled Event';
            break;
        case eventNameLC.includes('close'):
        case eventNameLC.includes('closure'):
        case eventNameLC.includes('no school'):
            icon = <FontAwesomeIcon className="icon" icon={faBan} />;
            label = 'Closure';
            break;
        case eventNameLC.includes('notice'):
        case eventNameLC.includes('notification'):
        case eventNameLC.includes('reminder'):
            icon = <FontAwesomeIcon className="icon" icon={faFlag} />;
            label = 'Notice';
            break;
        case eventNameLC.includes('question'):
        case eventNameLC.includes('survey'):
            icon = <FontAwesomeIcon className="icon" icon={faClipboardList} />;
            label = 'Survey';
            break;
        case eventNameLC.includes('teacher'):
            icon = <FontAwesomeIcon className="icon" icon={faPersonChalkboard} />;
            label = 'Teacher Event';
            break;
        case eventNameLC.includes('homework'):
            icon = <FontAwesomeIcon className="icon" icon={faHighlighter} />;
            label = 'Homework';
            break;
        case eventNameLC.includes('quiz'):
        case eventNameLC.includes('test'):
        case eventNameLC.includes('exam'):
        case eventNameLC.includes('finals'):
            icon = <FontAwesomeIcon className="icon" icon={faCheckDouble} />;
            label = 'Exam';
            break;
        case eventNameLC.includes('first day'):
            icon = <FontAwesomeIcon className="icon" icon={faStarRegular} />;
            label = 'First Day';
            break;
        case eventNameLC.includes('last day'):
            icon = <FontAwesomeIcon className="icon" icon={faStar} />;
            label = 'Last Day';
            break;
        case eventNameLC.includes('birthday'):
            icon = <FontAwesomeIcon className="icon" icon={faBirthdayCake} />;
            label = 'Birthday';
            break;
        case eventNameLC.includes('halloween'):
            icon = <FontAwesomeIcon className="icon" icon={faGhost} />;
            label = 'Halloween';
            break;
        case eventNameLC.includes('holiday'):
            icon = <FontAwesomeIcon className="icon" icon={faUmbrellaBeach} />;
            label = 'Holiday';
            break;
        case eventNameLC.includes('award'):
            icon = <FontAwesomeIcon className="icon" icon={faAward} />;
            label = 'Awards Event';
            break;
        case eventNameLC.includes('graduation'):
            icon = <FontAwesomeIcon className="icon" icon={faGraduationCap} />;
            label = 'Graduation';
            break;
        case eventNameLC.includes('photo'):
        case eventNameLC.includes('picture'):
            icon = <FontAwesomeIcon className="icon" icon={faCameraRetro} />;
            label = 'Photos';
            break;
        case eventNameLC.includes('construction'):
            icon = <FontAwesomeIcon className="icon" icon={faPersonDigging} />;
            label = 'Construction';
            break;
        case eventNameLC.includes('walking trip'):
        case eventNameLC.includes('walking'):
            icon = <FontAwesomeIcon className="icon" icon={faPersonWalking} />;
            label = 'Field Trip';
            break;
        case eventNameLC.includes('bus trip'):
        case eventNameLC.includes('trip'):
            icon = <FontAwesomeIcon className="icon" icon={faBusSimple} />;
            label = 'Field Trip';
            break;
        case eventNameLC.includes('school'):
            icon = <FontAwesomeIcon className="icon" icon={faSchool} />;
            label = 'School Event';
            break;
        case eventNameLC.includes('library'):
            icon = <FontAwesomeIcon className="icon" icon={faBook} />;
            label = 'Library';
            break;
        case eventNameLC.includes('lunch'):
        case eventNameLC.includes('dinner'):
        case eventNameLC.includes('breakfast'):
        case eventNameLC.includes('pizza'):
        case eventNameLC.includes('food'):
            icon = <FontAwesomeIcon className="icon" icon={faUtensils} />;
            label = 'Meal Time';
            break;
        case eventNameLC.includes('weather'):
            icon = <FontAwesomeIcon className="icon" icon={faUmbrella} />;
            label = 'Weather Notice';
            break;
        case eventNameLC.includes('science'):
            icon = <FontAwesomeIcon className="icon" icon={faFlask} />;
            label = 'Science';
            break;
        case eventName.includes('FC '):
        case eventName.includes(' FC'):
        case eventNameLC.includes('soccer'):
            icon = <FontAwesomeIcon className="icon" icon={faSoccerBall} />;
            label = 'Soccer';
            break;
        case eventNameLC.includes('basketball'):
            icon = <FontAwesomeIcon className="icon" icon={faBasketball} />;
            label = 'Basketball';
            break;
        case eventNameLC.includes('baseball'):
            icon = <FontAwesomeIcon className="icon" icon={faBaseball} />;
            label = 'Baseball';
            break;
        case eventNameLC.includes('volleyball'):
            icon = <FontAwesomeIcon className="icon" icon={faVolleyball} />;
            label = 'Volleyball';
            break;
        case eventNameLC.includes('tennis'):
            icon = <FontAwesomeIcon className="icon" icon={faTableTennisPaddleBall} />;
            label = 'Tennis';
            break;
        case eventNameLC.includes('snowboard'):
            icon = <FontAwesomeIcon className="icon" icon={faSnowboarding} />;
            label = 'Snowboarding';
            break;
        case eventNameLC.includes('ski'):
            icon = <FontAwesomeIcon className="icon" icon={faSkiing} />;
            label = 'Skiing';
            break;
        case eventNameLC.includes('football'):
            icon = <FontAwesomeIcon className="icon" icon={faFootball} />;
            label = 'Football';
            break;
        case eventNameLC.includes('chess'):
            icon = <FontAwesomeIcon className="icon" icon={faChess} />;
            label = 'Chess Club';
            break;
        case eventNameLC.includes('run'):
            icon = <FontAwesomeIcon className="icon" icon={faPersonRunning} />;
            label = 'Running Event';
            break;
        case eventNameLC.includes('bike'):
            icon = <FontAwesomeIcon className="icon" icon={faPersonBiking} />;
            label = 'Bicyle Event';
            break;
        case eventNameLC.includes('bowl'):
            icon = <FontAwesomeIcon className="icon" icon={faBowlingBall} />;
            label = 'Bowling';
            break;
        case eventNameLC.includes('golf'):
            icon = <FontAwesomeIcon className="icon" icon={faGolfBall} />;
            label = 'Golf';
            break;
        case eventNameLC.includes('hockey'):
            icon = <FontAwesomeIcon className="icon" icon={faHockeyPuck} />;
            label = 'Hockey';
            break;
        case eventNameLC.includes('dj '):
        case eventNameLC.includes('music'):
        case eventNameLC.includes('concert'):
        case eventNameLC.includes('recital'):
            icon = <FontAwesomeIcon className="icon" icon={faMusic} />;
            label = 'Music Event';
            break;
        case eventNameLC.includes('band'):
            icon = <FontAwesomeIcon className="icon" icon={faDrum} />;
            label = 'Band';
            break;
        case eventNameLC.includes('news '):
        case eventNameLC.includes(' news'):
        case eventNameLC.includes('newsletter'):
            icon = <FontAwesomeIcon className="icon" icon={faNewspaper} />;
            label = 'News';
            break;
        case eventNameLC.includes('ninja'):
            icon = <FontAwesomeIcon className="icon" icon={faUserNinja} />;
            label = 'Ninja';
            break;
        default:
            if (includeLabel) icon = <FontAwesomeIcon className="icon" icon={faCalendarCheck} />;
            label = 'Event';
    }

    return (
        <>
            {icon}{includeLabel ? label : null}
        </>
    );
};

export default EventIconLabel;
