import React from 'react';

const Join = () => {
  return (
  <div className='join'>
    <h2>Simple Schedule follows <br/>school and sports for you</h2>
    <h2>Effortlessly know what's <br/>next for your kids</h2>
    <button className='google-button' onClick={() => { window.location.href = '/api/auth/google'}}>Sign In</button>
  </div>
  );
};

export default Join;