import React from 'react';
import { Link } from 'react-router-dom';
import SuccessIcon from '../assets/SuccessIcon'; 

const JoinSuccess = () => {
  return (
    <div className="success">
      <h1>Ready</h1>
      <br/>
      <SuccessIcon size={100} color="#ff7f00" />
      <br/>
      <br/>
      <br/>
      <br/>
      <Link to="/focus">
        <button className='continue'>Continue</button>
      </Link>
    </div>
  );
};

export default JoinSuccess;
